/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W styczniu 2024 r. Transpol Lider Sp. z o.o. Sp. k. zakończył zadanie inwestycyjne polegające na rozbudowie drogi powiatowej nr 2594C na odcinku Jacewo-Marulewy."), "\n", React.createElement(_components.p, null, "W ramach modernizacji wykonano następujące zadania:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "budowa ścieżki pieszo - rowerowej o szer. 2,50 m po stronie lewej drogi powiatowej nr 2594C,"), "\n", React.createElement(_components.li, null, "przebudowa istniejących zjazdów do przyległych nieruchomości,"), "\n", React.createElement(_components.li, null, "przebudowa kolidującej infrastruktury,"), "\n", React.createElement(_components.li, null, "wycinka kolidującego zadrzewienia"), "\n", React.createElement(_components.li, null, "reprofilacja istniejących rowów oraz przebudowa istniejących przepustów celem zachowania ich funkcji polegającej na zapewnieniu prawidłowego odwodnienia korpusu drogowego,"), "\n", React.createElement(_components.li, null, "wykonanie kanału technologicznego"), "\n"), "\n", React.createElement(_components.p, null, "Czas realizacji inwestycji przewidziany był na 2 miesiące od daty rozpoczęcia robót."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
